import React, { ReactChild } from 'react';
import moment from 'moment';

import { Switches } from 'z-frontend-network';

import { Banner, BannerFlexContainerProps } from 'z-frontend-composites';
import { Link } from 'z-frontend-elements';
import { TextBlock, TextInline } from 'zbase';
import { BRAND_NAME } from 'z-frontend-app-bootstrap';

import { ActiveBillingDetailsQuery } from '../gqlTypes';
import {
  renewalDateFormat,
  v3BasePlanSkus,
  EXTENDED_SUBSCRIPTION_SWITCH,
  V3_GREEN_COHORT_STATIC_MESSAGE,
} from './constants';
import { CheckoutPackageProductSku } from '../../schema/schemaTypes';

type RenewalNoticeNewProps = {
  switches: Switches;
  billingDetails: ActiveBillingDetailsQuery.ActiveBillingDetails;
  isGreenCohort: boolean;
  disableRenewalBanner?: boolean;
} & BannerFlexContainerProps;

const showRenewalNotice = (
  billingDetails: ActiveBillingDetailsQuery.ActiveBillingDetails,
  disableRenewalBanner: boolean,
  isV4GreenCohort: boolean,
): boolean => {
  if (!billingDetails.renewalDate) {
    return false;
  }

  const { numberOfDaysBeforeRenewal } = billingDetails;
  const isRenewalSoon = numberOfDaysBeforeRenewal >= 0 && numberOfDaysBeforeRenewal <= 60;

  // Show the banner if a company is Green cohort, or is renewing within 60 days
  // Green cohorts should see the banner regardless of within the 60 days
  const isSoonOrGreen = isRenewalSoon || isV4GreenCohort;
  if (!isSoonOrGreen) {
    return false;
  }

  if (disableRenewalBanner) {
    return false;
  }

  const { planDetails } = billingDetails;
  const currentPlanIsAnnual = planDetails.contractLength > 0;
  const currentPlanIsMonthly = !currentPlanIsAnnual;
  if (currentPlanIsMonthly) {
    return false;
  }

  const { isRenewingContract } = billingDetails;
  if (!isRenewingContract) {
    return false;
  }

  const baseProductSku = billingDetails?.planDetails?.name;
  const isV3BasePlan = v3BasePlanSkus.includes(baseProductSku as CheckoutPackageProductSku);
  const isV3BasePlanOrV4GreenCohort = isV3BasePlan || isV4GreenCohort;
  if (!isV3BasePlanOrV4GreenCohort) {
    // Only show renewal notice if company is on a v3 base plan or v4 green cohort
    return false;
  }

  return true;
};

const RenewalNoticeNew: React.FunctionComponent<RenewalNoticeNewProps> = props => {
  const { billingDetails, isGreenCohort, disableRenewalBanner, switches, ...containerProps } = props;
  const { version: billingVersion, numberOfDaysBeforeRenewal } = billingDetails;

  const isV4Billing = billingVersion === 4;
  const isV3Billing = billingVersion === 3;
  const isV4GreenCohort = isV4Billing && isGreenCohort;
  const isWithin60Days = numberOfDaysBeforeRenewal >= 0 && numberOfDaysBeforeRenewal <= 60;

  // TODO: isV4GreenCohort is calculated from billingDetails and isGreenCohort, so might be more flexible to pass
  // isGreenCohort instead of isV4GreenCohort into showRenewalNotice
  if (!showRenewalNotice(billingDetails, disableRenewalBanner, isV4GreenCohort)) {
    return null;
  }

  const isV3Extended = switches[EXTENDED_SUBSCRIPTION_SWITCH];
  const momentDate = moment(billingDetails.renewalDate, 'YYYY-MM-DD');
  const formattedRenewalDate: string = momentDate.format(renewalDateFormat);
  const momentDayBeforeRenewal = momentDate.clone().subtract(1, 'day');
  const formattedDayBeforeRenewal: string = momentDayBeforeRenewal.format(renewalDateFormat);
  const notifyByDate = momentDate.subtract(30, 'day').format(renewalDateFormat);
  const currentBasePlanName = billingDetails.planDetails.displayName;

  const getBannerContent = (): [ReactChild, string, number] => {
    if (isV4Billing) {
      if (isWithin60Days) {
        // V4 plan and
        // Less than or equal to 60 days before a renewal
        return [
          `Your renewal date of your current contract is coming soon, ${formattedRenewalDate}.`,
          'See details of your upgrade.',
          0,
        ];
      }

      if (isGreenCohort) {
        // V4 plan and
        // More than 60 days before a renewal and
        // Green cohort
        return [
          <>
            Your renewal date of your current contract is coming soon, {formattedRenewalDate}. We have upgraded your
            plan to {currentBasePlanName}, giving you early access to our entire technology suite, including new
            features. Your current contract terms remain unchanged.
          </>,
          'See details of your upgrade.',
          0,
        ];
      }

      // V4 and
      // More than 60 days before a renewal and
      // Not green cohort
      return [null, null, null];
    }

    // Company does not have a v4 plan

    if (isV3Billing) {
      if (isGreenCohort) {
        // V3 and green cohort
        return [V3_GREEN_COHORT_STATIC_MESSAGE, '', 0];
      }

      if (isV3Extended) {
        // V3 and
        // Not in green cohort and
        // Extension switch is on
        return [
          <>
            <TextBlock mb={2}>
              <TextInline tag="strong">
                Your current {BRAND_NAME} contract renewal date has been extended to {formattedDayBeforeRenewal}.
              </TextInline>
            </TextBlock>
            On {formattedRenewalDate}, you will be enrolled in the HR Plus HR Advisory package for a 12-month term at
            the new pricing, unless you give us proper notice of non-renewal.
          </>,
          'See details about the plan and pricing',
          2,
        ];
      }

      // V3 and
      // Not in green cohort and
      // Not in extension group

      return [
        <>
          <TextBlock mb={2}>
            <TextInline tag="strong">
              Your current {BRAND_NAME} contract is about to renew on {formattedRenewalDate}.
            </TextInline>
          </TextBlock>
          If you take no action by {notifyByDate}, you will be enrolled in the HR Plus HR Advisory package for a
          12-month term at the new pricing.{' '}
        </>,
        'See details about the plan and pricing',
        0,
      ];
    }

    // Not v4 and not v3
    return [null, null, null];
  };

  const [bannerText, linkText, marginBottom] = getBannerContent();

  if (!bannerText) return null;

  return (
    <Banner type="info" {...containerProps}>
      <TextBlock mb={marginBottom}>
        {bannerText} {linkText && <Link href="/app/hr-employee/#/renewal-pricing">{linkText}</Link>}
      </TextBlock>
    </Banner>
  );
};

export default RenewalNoticeNew;
