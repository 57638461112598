import React, { Component } from 'react';
import gql from 'graphql-tag';
import { isEmpty } from 'lodash';

import { useSwitches, Query } from 'z-frontend-network';
import { BannerFlexContainerProps } from 'z-frontend-composites';

import { ActiveBillingDetailsQuery } from '../gqlTypes';
import RenewalNotice from './RenewalNotice';
import RenewalNoticeNew from './RenewalNoticeNew';
import ReadOnlyUpgradeCountDownNotice, { READ_ONLY_TRIAL_SKU_NAME } from './ReadOnlyUpgradeCountDownNotice';
import { CONTRACTOR_PAYMENTS, GREEN_COHORT_TYPE, IOM, RENEWAL_CENTER_REVAMP_SWITCH } from './constants';
import { isCancellationRequestActive } from './utils';

const FULL_COMPANY_ADMIN = 'is_full_company_admin';

type RenewalNoticeContainerProps = BannerFlexContainerProps;

function isRenewalNoticeValid(data: ActiveBillingDetailsQuery.Query) {
  const { rolesPermissionsData, dashboard, activeBillingDetails } = data;

  if (!activeBillingDetails) {
    return false;
  }

  const isFullAdmin = rolesPermissionsData?.grants?.hasOwnProperty(FULL_COMPANY_ADMIN);
  // yourPeople3/zenefits_hub/misc/zuora/repos.py
  // 'renewalDate': subscription.endDate
  const { renewalDate } = activeBillingDetails;

  if (!isFullAdmin || dashboard.isTrialCompany || !renewalDate) {
    return false;
  }

  return true;
}

// Although this component is called RenewalNoticeContainer
// it is also responsible to show ReadOnlyUpgradeCountDownNotice
export default class RenewalNoticeContainer extends Component<RenewalNoticeContainerProps> {
  render() {
    const { ...rest } = this.props;

    return (
      <Query<ActiveBillingDetailsQuery.Query>
        query={activeBillingDetailsQuery}
        handleLoading={false}
        handleError={false}
        isBackgroundQuery
      >
        {({ data, loading, error }) => {
          const switches = useSwitches();

          if (isEmpty(switches)) {
            // When switch data is still loading, `switches` is an empty object {}.
            return null;
          }

          if (error) {
            console.error('GraphQL error in RenewalNoticeContainer:', error);
            return null;
          }

          if (loading || !data || !isRenewalNoticeValid(data)) {
            return null;
          }

          if (isCancellationRequestActive(data.dashboard?.company?.cancellationRequest)) {
            // Company has an active cancellation request, so do not show renewal notice
            return null;
          }

          const { activeBillingDetails } = data;
          const isIom = data?.dashboard?.features?.includes(IOM);
          const isContractorPaymentsCompany = data?.dashboard?.features?.includes(CONTRACTOR_PAYMENTS);
          const isCpWithIomCompany = isIom && isContractorPaymentsCompany;
          const disableRenewalBanner = isIom || isCpWithIomCompany;
          const planDetails = activeBillingDetails && activeBillingDetails.planDetails;
          const skuName = planDetails && planDetails.name;
          // TODO: there is also a function called showRenewalNotice inside RenewalNoticeNew.tsx
          // would be good to combine them into one.
          const showRenewalNotice = skuName !== READ_ONLY_TRIAL_SKU_NAME;
          const isGreenCohort = data?.dashboard?.company?.salesforceAccount?.cohortType === GREEN_COHORT_TYPE;

          return (
            <>
              {showRenewalNotice ? (
                switches[RENEWAL_CENTER_REVAMP_SWITCH] ? (
                  <RenewalNoticeNew
                    switches={switches}
                    billingDetails={activeBillingDetails}
                    isGreenCohort={isGreenCohort}
                    disableRenewalBanner={disableRenewalBanner}
                    {...rest}
                  />
                ) : (
                  <RenewalNotice
                    billingDetails={activeBillingDetails}
                    includeWalkMe={false}
                    disableRenewalBanner={disableRenewalBanner}
                    {...rest}
                  />
                )
              ) : (
                <ReadOnlyUpgradeCountDownNotice billingDetails={activeBillingDetails} {...rest} />
              )}
            </>
          );
        }}
      </Query>
    );
  }
}

const activeBillingDetailsQuery = gql`
  query ActiveBillingDetailsQuery {
    dashboard {
      id
      switches
      isTrialCompany
      features
      company {
        id
        salesforceAccount {
          id
          cohortType
        }
        cancellationRequest {
          id
          status
          effectiveCancellationDate
        }
      }
    }
    rolesPermissionsData {
      grants
    }
    activeBillingDetails {
      version
      system_version
      renewalDate
      numberOfDaysBeforeRenewal
      isRenewingContract
      planDetails {
        name
        displayName
        contractLength
        billingPeriod
        numSeats
      }
    }
  }
`;
