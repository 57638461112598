import React, { useContext, useEffect, Component, FunctionComponent } from 'react';
import { get } from 'lodash';

import { getDashboardApps, DashboardApp, PageLayout, ProductPageContainer, TopNavBar } from 'z-frontend-layout';
import { checkPermission, usePermissions, Permissions, Query } from 'z-frontend-network';
import { Box, Flex } from 'zbase';
import { Hide, Render } from 'z-frontend-theme';
import { DialogManager } from 'z-frontend-overlays';
import {
  paywallRedirectGroupAllowList,
  useDocumentTitle,
  useRefreshPrerequisites,
  ErrorBoundary,
  PrerequisiteRedirect,
} from 'z-frontend-app-bootstrap';
import { PrerequisiteContext } from 'z-frontend-app-bootstrap/src/prerequisite/PrerequisiteManager';
import { RenewalNoticeContainer } from 'z-frontend-hr-components';

import { useSetFullPageReactClass } from './hooks';
import ContactsContainer from './components/ContactsContainer';
import PaymentBanner from './components/PaymentBanner';
import PayrollCorrectionsBannerContainer from './components/PayrollCorrectionsBannerContainer';
import { AppsAvailableQuery } from './gqlTypes';
import { DashboardRole } from './types';
import TrialPromoBannerContainer from './components/trial-promo/TrialPromoBannerContainer';
import MarketplaceBannerContainer from './components/marketplace-banner/MarketplaceBannerContainer';
import WelcomeHeading from './components/WelcomeHeading';
import DashboardModeToggle from './components/DashboardModeToggle';
import QuickLinksContainer from './components/QuickLinksContainer';
import PayrollDeadlineModalContainer from './components/company-setup/PayrollDeadlineModal';
import { ZApp } from '../schema/schemaTypes';
import CompanyLogoContainer from './components/company-logo/CompanyLogoContainer';
import { WorkerContainer } from './components/workers/WorkerContainer';
import AppListContainer from './components/apps/AppListContainer';
import PageFooter from './components/PageFooter';
import AccountUpdateModal from './components/account-update-modal/AccountUpdateModal';
import { filterAppsBySwitches, parseAppStatus } from './components/apps/app-utils';
import { dashboardPageQuery } from './initialQueries';
import RedirectModal from './components/redirect-modal/RedirectModal';
import CompanySetupNoticeContainer from './components/company-setup/CompanySetupNoticeContainer';
import InboxActionContainer from './components/inbox-actions/InboxActionContainer';
import { OnboardingHelpContainer } from './components/OnboardingHelpContainer';
import TalentMicroTrialBanner from './components/TalentMicroTrialBanner';
import { CONTRACTOR_PAYMENTS, IOM } from './constants';
import { FULL_COMPANY_ADMIN, MAIN_ADMIN } from './components/apps/constants';
import EvergreenSubscriptionEndingNoticeBanner from './components/dashboard/EvergreenSubscriptionEndingNoticeBanner';

function getApps(data: AppsAvailableQuery.Query): ZApp[] {
  if (!data || !data.dashboard) {
    return [];
  }
  return data.dashboard.zAppInstallSubscriptions.map(subscription => subscription.appInstall.app);
}

function getFilteredApps(data: AppsAvailableQuery.Query): DashboardApp[] {
  if (!data || !data.dashboard) {
    return [];
  }
  const { zAppInstallSubscriptions, switches, isSpoofing } = data.dashboard;
  const apps = getDashboardApps(zAppInstallSubscriptions, switches, isSpoofing);
  return filterAppsBySwitches(apps, switches);
}

function canSeeDashboardForRole(apps: DashboardApp[], roleToFind: DashboardRole) {
  return apps.some(app => {
    const role = get(app, 'subscription.appInstall.app.role');
    return role === roleToFind;
  });
}

function canViewSocialFeedOnDashboard(apps: DashboardApp[]): boolean {
  if (apps.length < 1) {
    return false;
  }

  return apps.some(
    app =>
      ['1.com.zenefits.PeopleHubEmployee', '1.com.zenefits.PeopleHubAdmin'].includes(
        app.subscription?.appInstall?.app?.uniqueId,
      ) &&
      app.subscription?.appInstall?.status &&
      parseAppStatus(app.subscription?.appInstall?.status) === 'enabled',
  );
}

function shouldShowRedirectModal(redirects: PrerequisiteRedirect[]) {
  const [redirect] = redirects;
  if (!redirect) {
    return false;
  }

  if (redirect && !paywallRedirectGroupAllowList.some(group => redirect.group === group)) {
    return false;
  }
  return true;
}

type DashboardProps = {
  apps: DashboardApp[];
  allApps: ZApp[];
  canViewAdmin: boolean;
  canViewEmployee: boolean;
  canViewSocialFeed: boolean;
  loading: boolean;
  isAdmin: boolean;
  redirects: PrerequisiteRedirect[];
  isIOM: boolean;
  isContractorPaymentsCompany: boolean;
  permissions: Permissions;
};
type DashboardState = {
  role: DashboardRole;
};

class Dashboard extends Component<DashboardProps, DashboardState> {
  constructor(props: DashboardProps) {
    super(props);
    this.state = {
      role: null, // Wait until we know what the user can access to set
    };

    if (window.embeddedReactApps && window.embeddedReactApps.boot) {
      window.embeddedReactApps.boot.fullPageActive = true;
    }
  }

  static getDerivedStateFromProps(props: DashboardProps, state: DashboardState) {
    // Role hasn't been initialized yet
    if (state.role === null && !props.loading) {
      return {
        role: props.canViewAdmin ? DashboardRole.admin : DashboardRole.employee,
      };
    }
    return null;
  }

  componentWillUnmount() {
    if (window.embeddedReactApps && window.embeddedReactApps.boot) {
      window.embeddedReactApps.boot.fullPageActive = false;
    }
  }

  render() {
    const {
      canViewAdmin,
      canViewEmployee,
      canViewSocialFeed,
      loading,
      apps,
      allApps,
      isAdmin,
      redirects,
      isIOM,
      isContractorPaymentsCompany,
      permissions,
    } = this.props;
    const { role } = this.state;
    const isCPWithIOMCompany = isIOM && isContractorPaymentsCompany;

    let hideWorkerContainer =
      (isIOM && !isCPWithIOMCompany) || (isContractorPaymentsCompany && role !== DashboardRole.admin);

    if (!hideWorkerContainer && isCPWithIOMCompany) {
      const viewContractors =
        checkPermission(permissions, FULL_COMPANY_ADMIN) || checkPermission(permissions, MAIN_ADMIN);
      hideWorkerContainer = !viewContractors;
    }

    return (
      <>
        <PageLayout mode="fixed" columns="2-8-2">
          <PageLayout.Nav order={1}>
            <CompanyLogoContainer />
          </PageLayout.Nav>
          <PageLayout.Aside order={3}>
            <Flex direction="column">
              <Box order={[2, null, 1]}>
                <QuickLinksContainer role={role} />
              </Box>
              <Box order={[1, null, 2]}>
                <OnboardingHelpContainer role={role} />
              </Box>
              <Hide forBreakpoints={[true, true]}>
                <ContactsContainer role={role} />
              </Hide>
            </Flex>
          </PageLayout.Aside>
          <PageLayout.Main order={2}>
            <DialogManager
              render={dialog => {
                return (
                  <>
                    {redirects && shouldShowRedirectModal(redirects) && <RedirectModal redirects={redirects} />}
                    {isAdmin && <PaymentBanner />}
                    {isAdmin && <TrialPromoBannerContainer />}
                    {isAdmin && <MarketplaceBannerContainer mb={4} />}
                    {isAdmin && <PayrollCorrectionsBannerContainer />}

                    <Flex
                      align="center"
                      justify={['center', null, 'space-between']}
                      mt={[3, null, 0]}
                      mb={[3, null, 0]}
                    >
                      <WelcomeHeading />
                      {!loading && (
                        <DashboardModeToggle
                          canViewAdmin={canViewAdmin}
                          canViewEmployee={canViewEmployee}
                          role={role}
                          onRoleChange={role => {
                            this.setState({ role });
                          }}
                        />
                      )}
                    </Flex>
                    {isAdmin && <CompanySetupNoticeContainer />}
                    <InboxActionContainer apps={allApps} canViewSocialFeed={canViewSocialFeed} />
                    {!hideWorkerContainer && (
                      <WorkerContainer
                        role={role}
                        apps={apps}
                        canViewAdmin={canViewAdmin}
                        cpWorkersOnly={isCPWithIOMCompany}
                      />
                    )}
                    <AppListContainer role={role} isIOM={isIOM} />
                    <Render forBreakpoints={[true, true]}>
                      <ContactsContainer role={role} mt={5} />
                    </Render>
                    {isAdmin && <AccountUpdateModal role={role} />}
                    {isAdmin && <PayrollDeadlineModalContainer dialog={dialog} />}
                  </>
                );
              }}
            />
          </PageLayout.Main>
        </PageLayout>
      </>
    );
  }
}

const DashboardContainer: FunctionComponent = () => {
  useDocumentTitle();
  useSetFullPageReactClass();
  const refreshPrerequisites = useRefreshPrerequisites();

  useEffect(() => {
    refreshPrerequisites();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { prerequisiteData } = useContext(PrerequisiteContext);
  const { permissions } = usePermissions();

  return (
    <>
      <TopNavBar
        productTitleKey="nav.productTitle"
        productTitleDefault="Dashboard"
        showInbox
        useClientHamburgerContent
        hasShadow // we have no ProductNavContainer, so provide shadow here
      />

      <Query<AppsAvailableQuery.Query> query={dashboardPageQuery} handleLoading={false}>
        {({ data: pageData, loading }) => {
          const filteredApps = getFilteredApps(pageData);
          const canViewAdmin = canSeeDashboardForRole(filteredApps, DashboardRole.admin);
          const canViewEmployee = canSeeDashboardForRole(filteredApps, DashboardRole.employee);
          const canViewSocialFeed = canViewSocialFeedOnDashboard(filteredApps);
          const isAdmin =
            pageData && pageData.dashboard && pageData.dashboard.permission && pageData.dashboard.permission.isAdmin;
          const apps = getApps(pageData);
          const isIOM = pageData?.dashboard?.features?.includes(IOM);
          const isContractorPaymentsCompany = pageData?.dashboard?.features?.includes(CONTRACTOR_PAYMENTS);

          return (
            <>
              <ErrorBoundary FallbackComponent={() => null}>
                {isAdmin && <RenewalNoticeContainer mb={4} />}

                <EvergreenSubscriptionEndingNoticeBanner />
              </ErrorBoundary>
              <TalentMicroTrialBanner />
              <ProductPageContainer
                style={{ minHeight: '90vh' }} // TODO: consider adding footer to PageLayout instead
                pb={4}
              >
                <Dashboard
                  loading={loading}
                  apps={filteredApps}
                  allApps={apps}
                  canViewEmployee={canViewEmployee}
                  canViewAdmin={canViewAdmin}
                  canViewSocialFeed={canViewSocialFeed}
                  isAdmin={!!isAdmin}
                  redirects={prerequisiteData}
                  isIOM={isIOM}
                  isContractorPaymentsCompany={isContractorPaymentsCompany}
                  permissions={permissions}
                />
              </ProductPageContainer>
            </>
          );
        }}
      </Query>

      <PageFooter />
    </>
  );
};

export default DashboardContainer;
