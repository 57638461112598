import { CheckoutPackageProductSku } from '../schema/schemaTypes';

export const basePlanSkus = [
  CheckoutPackageProductSku.V3_TIER_ONE,
  CheckoutPackageProductSku.V3_TIER_TWO,
  CheckoutPackageProductSku.V3_TIER_THREE,
];

export const IOM = 'iom';
export const CONTRACTOR_PAYMENTS = 'contractorPayments';

export const CSM_OWNER_ROLE = 'Customer Success Manager';
export const LONGTAIL_RENEWAL_EMAIL = 'renewals@trinet.com';
