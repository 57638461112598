import moment from 'moment-timezone';

import { ZUORA_TIMEZONE } from 'z-frontend-layout';

import { ActiveBillingDetailsQuery } from '../gqlTypes';
import { CancellationRequestStatus } from '../../schema/schemaTypes';

export const isCancellationRequestActive = (cancellationRequest: ActiveBillingDetailsQuery.CancellationRequest) => {
  if (!cancellationRequest) {
    return false;
  }

  const { status, effectiveCancellationDate } = cancellationRequest;

  if (
    // For definitions, see `class CancellationRequestStatus` in yourPeople3
    [
      CancellationRequestStatus.initial,
      CancellationRequestStatus.inProgress,
      CancellationRequestStatus.completed,
    ].includes(status)
  ) {
    // Status is initial, in_progress or completed, check effectiveCancellationDate.
    // If effectiveCancellationDate is today or in the future, request is active,
    // else request is not active.
    const startOfEffectiveCancellationDate = moment.tz(effectiveCancellationDate, ZUORA_TIMEZONE);
    const todayDate = moment()
      .tz(ZUORA_TIMEZONE)
      .format('YYYY-MM-DD');
    const startOfToday = moment.tz(todayDate, ZUORA_TIMEZONE);

    if (startOfEffectiveCancellationDate.isSameOrAfter(startOfToday)) {
      // Effective cancellation date is today or in the future
      // Cancellation request is active
      return true;
    }

    // Effective cancellation date is in the past, so cancellation request is not active.
    return false;
  }

  // status is deleted or expired, request is not active
  return false;
};
