import gql from 'graphql-tag';
import React, { FunctionComponent } from 'react';
import { isEmpty } from 'lodash';

import { Banner } from 'z-frontend-composites';
import { useQueryWithProgress } from 'z-frontend-network';
import { Link } from 'z-frontend-elements';
import { TextBlock } from 'zbase';

import { EvergreenSubscriptionEndingNoticeBannerQuery } from '../../gqlTypes';
import { FULL_COMPANY_ADMIN } from '../apps/constants';
import { basePlanSkus, CSM_OWNER_ROLE, LONGTAIL_RENEWAL_EMAIL } from '../../constants';
import { CheckoutPackageProductSku } from '../../../schema/schemaTypes';

const evergreenSubscriptionEndingNoticeBannerQuery = gql`
  query EvergreenSubscriptionEndingNoticeBannerQuery {
    rolesPermissionsData {
      grants
    }
    activeBillingDetails {
      planDetails {
        name
        contractLength
      }
    }
    dashboard(id: "me") {
      id
      company {
        id
        salesforceAccount {
          id
          owner {
            role
            email
          }
        }
      }
    }
  }
`;

const EvergreenSubscriptionEndingNoticeBanner: FunctionComponent = () => {
  const { Loading, QueryError, data } = useQueryWithProgress<EvergreenSubscriptionEndingNoticeBannerQuery.Query>(
    evergreenSubscriptionEndingNoticeBannerQuery,
  );

  /**
   * isEmpty is used to detect when data is an empty object.
   * It happens for non-admin employees because activeBillingDetails can only be accessed by admins.
   */
  if (Loading || QueryError || isEmpty(data)) {
    return null;
  }

  const isFullAdmin = data.rolesPermissionsData?.grants?.hasOwnProperty(FULL_COMPANY_ADMIN);

  const baseProductSku = data.activeBillingDetails.planDetails?.name;
  const isV3BasePlan = basePlanSkus.includes(baseProductSku as CheckoutPackageProductSku);

  const contractLength = data.activeBillingDetails.planDetails?.contractLength;
  const isEvergreen = !contractLength;

  if (!isFullAdmin || !isV3BasePlan || !isEvergreen) {
    return null;
  }

  const salesForceAccountOwner = data.dashboard.company?.salesforceAccount?.owner;
  const isCsm = salesForceAccountOwner?.role === CSM_OWNER_ROLE;
  const email = (isCsm && salesForceAccountOwner?.email) || LONGTAIL_RENEWAL_EMAIL;

  return (
    <Banner type="info">
      <TextBlock>
        ACTION REQUIRED! Your month-to-month subscription will end on 4/30/25 unless you take action now. Click{' '}
        <Link href={`mailto:${email}`}>here</Link> to contact the Renewals team.
      </TextBlock>
    </Banner>
  );
};

export default EvergreenSubscriptionEndingNoticeBanner;
