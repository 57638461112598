import React, { FunctionComponent, MouseEventHandler } from 'react';
import { StatelessComponent } from 'react-redux';

import { Box, Flex, FlexProps, Image, TextBlock, TextBlockProps } from 'zbase';
import { styled } from 'z-frontend-theme';
import { color, depth } from 'z-frontend-theme/utils';
import { EmberRouteLink, Link, Skeleton } from 'z-frontend-elements';
import { useSwitches, Switches } from 'z-frontend-network';
import { DialogManager } from 'z-frontend-overlays';

import AspectRatio from '../AspectRatio';
import { AppTileCard } from './app-utils';
import AppTilePromoBadge from './AppTilePromoBadge';
import { getPromoDetails, PromoDetails } from './promo-utils';
import AppTileUpgradeBadge from './AppTileUpgradeBadge';
import { INTERNAL_DOMAINS } from './constants';
import RedirectConfirmationModal from './RedirectionConfirmationModal';

const BoxFiltered = ({ isEnrolled, ...rest }: { isEnrolled: boolean }) => <Box {...rest} />;

const DashboardZAppStatusNotEnrolled = 'notEnrolled';

const DepthBox = styled(BoxFiltered)`
  position: relative;
  ${depth(0)};

  border-style: ${(props: { isEnrolled: boolean }) => (props.isEnrolled ? 'solid' : 'dashed')};
  border-width: ${(props: { isEnrolled: boolean }) => (props.isEnrolled ? '1' : '2')}px;

  &:hover {
    border-color: ${color('grayscale.e', 1)};
    ${(props: { isEnrolled: boolean }) => (props.isEnrolled ? depth(2) : '')};
  }

  background-color: ${(props: { isEnrolled: boolean }) =>
    props.isEnrolled ? color('grayscale.white') : color('grayscale.white', 0)};
`;

const AppTileImageContainer: StatelessComponent<{}> = ({ children }) => {
  return (
    <AspectRatio ratio={1}>
      <Flex align="center" justify="center" height={1} width={1}>
        {children}
      </Flex>
    </AspectRatio>
  );
};

const commonFlexProps: FlexProps = {
  align: 'flex-start',
  mt: 3,
  px: 3,
};

const commonTextProps: TextBlockProps = {
  textAlign: 'center',
  fontStyle: 'paragraphs.m',
  width: 1,
  height: 1,
  mt: 2,
};

type AppTileProps = AppTileCard & FlexProps;

// Skip redirect-route in these cases by directing straight to the hash.
const routeNameToHashMap: { [key: string]: string } = {
  'acacompliancesetup.index': '/acacompliancesetup/intro',
  'acacompliance.overview': '/acacompliance/overview/employeedetails',
  'ben-admin': '/ben-admin',
  'bizInsurance.overview': '/business-insurance',
  'commuter.intro': '/ucommuter/intro',
  companyProfile: '/company-profile',
  'compliance-companion': '/compliance-assistant',
  deductions: '/deductions',
  employeedirectory: '/employeedirectory',
  fsaIntro: '/fsaIntro',
  'fsasettings.overview': '/fsasettings/overview',
  'hrAdvisor.intro': '/hr-library/overview',
  'hsaSettings.overview': '/hsaSettings/overview',
  'onboarding.overview': '/onboarding/overview',
  'payroll.intro': '/payroll/intro',
  'payroll.pendingTasks.overview': '/payroll/pending-tasks/overview',
  'payrollIntegrations.dashboard': '/payroll-connect/overview',
  'payrollIntegrations.sync.selectProvider.intro': '/payroll-integrations/sync/select-provider/intro',
  'talent-intro': '/talent-intro',
  'timeattendance.intro': '/time/intro',
  'ucommutersettings.overview': '/ucommutersettings/overview',
};

function buildAppUrl(tileProps: Partial<AppTileProps>, appUrl: string) {
  return {
    appUrl,
    shouldUseHref: tileProps.appUrl?.startsWith('/app/'),
  };
}

function getAppLinkComponent(
  tileProps: Partial<AppTileProps> & { promoDetails: PromoDetails },
  switches: Switches,
  handleConsent: MouseEventHandler<HTMLAnchorElement>,
) {
  const { isThirdPartyApp, isPreInstallApp, isVisibleForEmployee, uniqueId, promoDetails } = tileProps;
  const { appUrl, shouldUseHref } = buildAppUrl(tileProps, promoDetails.href || tileProps.appUrl);
  const isExternal = promoDetails.isExternal || tileProps.isExternal;
  const clickHandler = promoDetails.action;

  if ((isThirdPartyApp && isVisibleForEmployee) || isExternal || isPreInstallApp) {
    const isReactUrl = appUrl && appUrl.includes('/app/');
    const openInNewWindow =
      (isThirdPartyApp && isVisibleForEmployee) || ((isExternal || isPreInstallApp) && !isReactUrl);
    return (props: React.PropsWithChildren<{}>) => (
      // Added an explicit check for `appUrl` if that is external link,
      // isExternal i.e promoDetails.isExternal || tileProps.isExternal sometimes returns undefined
      <Link
        width={1}
        href={isExternalUrl(appUrl) ? undefined : appUrl}
        target={openInNewWindow ? '_blank' : undefined}
        onClick={isExternalUrl(appUrl) ? handleConsent : clickHandler}
      >
        {props.children}
      </Link>
    );
  } else if (appUrl && routeNameToHashMap[appUrl]) {
    return (props: React.PropsWithChildren<{}>) => (
      <Link width={1} to={routeNameToHashMap[appUrl]} onClick={clickHandler}>
        {props.children}
      </Link>
    );
  } else if (shouldUseHref) {
    return (props: React.PropsWithChildren<{}>) => (
      <Link width={1} href={appUrl} onClick={clickHandler}>
        {props.children}
      </Link>
    );
  } else {
    const isIntegration = isThirdPartyApp && !isVisibleForEmployee;

    return (props: React.PropsWithChildren<{}>) => (
      <EmberRouteLink
        width={1}
        to={isIntegration ? 'integrations.integration.overview' : appUrl}
        routeParams={isIntegration ? [uniqueId] : undefined}
        onClick={clickHandler}
      >
        {props.children}
      </EmberRouteLink>
    );
  }
}

function isExternalUrl(url: string) {
  if (typeof url !== 'string') {
    return false;
  }
  return !url.includes(window.location.host) && !isInternalDomain(url) && url.startsWith('http');
}

function isInternalDomain(url: string) {
  let internal = false;
  INTERNAL_DOMAINS.forEach(domain => {
    internal = internal || url.includes(domain);
  });
  return internal;
}

export default function AppTile({
  name,
  iconUrl,
  appUrl,
  isThirdPartyApp,
  isExternal,
  isVisibleForEmployee,
  status,
  uniqueId,
  hideUpgradeBadge,
  ...rest
}: AppTileProps) {
  const switches = useSwitches();
  const promoDetails = getPromoDetails(uniqueId, switches);

  const tileProps = {
    isThirdPartyApp,
    isVisibleForEmployee,
    uniqueId,
    isExternal,
    appUrl,
    promoDetails,
    status,
  };

  const isEnrolled = status !== DashboardZAppStatusNotEnrolled; // eg 'enabled'
  const walkmeClass = `js-walkme-dashboard-app-${uniqueId ? uniqueId.replace(/\./g, '_') : ''}`;

  return (
    <DialogManager
      render={dialog => {
        /**
         * Handles consent of users when they click on any tile over the dashboard. Internal links are intact.
         * but for third party links this method opens a consent popup and on click of continue opens the link new tab
         */
        const handleConsent = () => {
          const builtURLInfo = buildAppUrl(tileProps, appUrl);

          if (isExternalUrl(builtURLInfo.appUrl)) {
            // setIsDialogVisible(true);
            dialog.open();
          } else if (!builtURLInfo.appUrl.startsWith('http')) {
            // opens react apps (first party) at current tab
            window.open(builtURLInfo.appUrl, '_self');
          } else {
            // opens third party URLs (external links) in new tab
            window.open(builtURLInfo.appUrl);
          }
        };

        const ComponentToRender = getAppLinkComponent(tileProps, switches, handleConsent);

        return (
          <>
            <RedirectConfirmationModal
              dialog={dialog}
              onConfirm={() => {
                window.open(appUrl);
                dialog.close();
              }}
              onCancel={() => {
                dialog.close();
              }}
            />
            <Flex data-testid="AppTile" className={walkmeClass} {...commonFlexProps} {...rest}>
              <ComponentToRender>
                <DepthBox bg="grayscale.white" height={1} width={1} isEnrolled={isEnrolled}>
                  <AppTileImageContainer>
                    <Image src={iconUrl} loading="lazy" alt={name} height={0.45} maxWidth={0.65} />
                  </AppTileImageContainer>
                  <AppTilePromoBadge promoDetails={promoDetails} />
                  {!hideUpgradeBadge && <AppTileUpgradeBadge uniqueId={uniqueId} status={status} />}
                </DepthBox>
                <TextBlock color="text.dark" {...commonTextProps}>
                  {name}
                </TextBlock>
              </ComponentToRender>
            </Flex>
          </>
        );
      }}
    />
  );
}

export const AppTileSkeleton: FunctionComponent<FlexProps> = props => {
  return (
    <Flex column data-testid="AppTileSkeleton" {...commonFlexProps} {...props}>
      <AppTileImageContainer>
        <Skeleton width={1} height={1} />
      </AppTileImageContainer>
      <TextBlock mb={3} {...commonTextProps}>
        <Skeleton width={64} />
      </TextBlock>
    </Flex>
  );
};
