import { CheckoutPackageProductSku } from '../../schema/schemaTypes';

export const CONTRACTOR_PAYMENTS = 'contractorPayments';
export const IOM = 'iom';
export const RENEWAL_CENTER_REVAMP_SWITCH = 'clc_renewal_center_revamp';
export const EXTENDED_SUBSCRIPTION_SWITCH = 'clc_extended_subscription';
export const GREEN_COHORT_TYPE = 'Green';
export const V3_GREEN_COHORT_STATIC_MESSAGE =
  'Get your custom renewal quote by clicking the chat icon in the lower-right corner of your dashboard to connect with your Support team.';
export const renewalDateFormat = 'MMMM D, YYYY';

export const v3BasePlanSkus = [
  CheckoutPackageProductSku.V3_TIER_ONE,
  CheckoutPackageProductSku.V3_TIER_TWO,
  CheckoutPackageProductSku.V3_TIER_THREE,
];
